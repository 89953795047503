
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';
import accountModule from '@/store/modules/accountModule';
import operationsModule from '@/store/modules/operationsModule';
import reportModule from '@/store/modules/reportModule';
import analysisModule from '@/store/modules/analysisModule';
import { getComponent } from '@/utils/helpers';
import workflowModule from '@/store/modules/workflowModule';

@Component({
  components: {
    LineChart: () => import('@/lib/charts/lineChart'),
    DoughnutChart: () => import('@/lib/charts/doughnutChart'),
    BarChart: () => import('@/lib/charts/barChart'),
    AppLoader: () => getComponent('common/AppLoader')
  },
})
export default class ReportPage extends mixins(DataLoading) {
  topWells = []

  rankings = []

  colors = ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#992c78', '#4BDF95', '#303279']

  actionsChartOptions = {
    events: ['click'],
    loaded: false,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    cutoutPercentage: 65,
  }

  actionsChartData = {
    labels: [],
    datasets: [{
      backgroundColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#992c78'],
      hoverBackgroundColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#992c78'],
      borderColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#992c78'],
      hoverBorderColor: '#FFFFFF',
      hoverBorderWidth: 3,
      borderWidth: 1,
      borderAlign: 'inner',
      data: [],
    }],
  }

  actionsTotal = 0

  barChartData: any = {
    labels: [],
    datasets: [],
  }

  barChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: "rgba(255,255,255,0.7)",
      }
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => `${data.datasets[tooltipItem.datasetIndex].label} ${parseFloat(tooltipItem.yLabel).toFixed()}`,
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        stacked: true,
        gridLines: {
          display: false,
        },
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        stacked: true,
        gridLines: {
          color: 'rgba(102, 106, 150, 0.05)',
        },
        ticks: {
          beginAtZero: true,
		  callback: function(value, index, values) {
				if(parseInt(value) >= 1000){
				return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				} else {
				return value;
				}
		  },
          fontColor: 'rgba(102, 106, 150, 0.3)',
          maxTicksLimit: 5,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
        },
      }],
    },
  }

  cycleChartData = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
       borderColor: '#2CE6C2',
		  backgroundColor: '#2CE6C2',
      borderWidth: 1.5,
      pointBackgroundColor: 'rgb(164, 162, 244)',
      pointBorderColor: 'rgb(164, 162, 244)',
      pointRadius: 0,
      fill: false,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  }

   actionChartData = {
    labels: [],
    datasets: [{
       label: '% Deferment Actioned',
        data: [],
        borderColor: '#2CE6C2',
		    backgroundColor: '#2CE6C2',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverRadius: 3,
        pointBorderColor: '#2CE6C2',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#FFFFFF',
        pointBackgroundColor: '#2CE6C2',
        yAxisID: 'y-axis-0',
        spanGaps: true,
    }],
  }

  cycleChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    hover: {
      mode: 'point',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    scales: {
      xAxes: [{
        type: 'time',
        ticks: {
          fontColor: '#666A96',
          fontStyle: 'bold',
          callback: (tick, index) => (index % 3 ? '' : tick),
          maxRotation: 0,
        },
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MM/DD',
          },
        },
        gridLines: {
          drawOnChartArea: false,
          color: 'rgba(102, 106, 150, 0.3)',
        },
      }],
      yAxes: [{
        ticks: {
          min: 0,
          fontColor: '#666A96',
          fontStyle: 'bold',
        },
        gridLines: {
          drawOnChartArea: false,
          color: 'rgba(102, 106, 150, 0.3)',
        },
      }],
    },
  }


    actionChartOptions = {
    responsive: true,
     legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: "rgba(255,255,255,0.7)",
      }
    },
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'point',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    scales: {
       xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
           fontColor: '#666A96',
          fontStyle: 'bold',
          maxTicksLimit: 8,
          minRotation: 0,
        },
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
         id: 'y-axis-0',
            type: 'linear',
          position: 'left',
        ticks: {
          min: 0,
          
          fontColor: '#666A96',
          fontStyle: 'bold',
        },
        gridLines: {
          drawOnChartArea: false,
          color: 'rgba(102, 106, 150, 0.3)',
        },
      }],
    },
  }

  jobCountsChartData = {
    labels: [],
    datasets: [],
  }

  jobCountsChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          display: false,
        },
      }],
      yAxes: [{
        stacked: true,
        gridLines: {
          color: 'rgba(102, 106, 150, 0.05)',
        },
        ticks: {
          beginAtZero: true,
          fontColor: 'rgba(102, 106, 150, 0.3)',
          maxTicksLimit: 5,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
        },
      }],
    },
  }

  completionChartData = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: 'rgb(164, 162, 244)',
      borderWidth: 1.5,
      pointBackgroundColor: 'rgb(164, 162, 244)',
      pointBorderColor: 'rgb(164, 162, 244)',
      pointRadius: 0,
      fill: false,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  }

  completionChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    hover: {
      mode: 'point',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    scales: {
      xAxes: [{
        type: 'time',
        ticks: {
          fontColor: '#666A96',
          fontStyle: 'bold',
        },
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MM/DD',
          },
        },
        gridLines: {
          drawOnChartArea: false,
          color: 'rgba(102, 106, 150, 0.3)',
        },
      }],
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          fontColor: '#666A96',
          fontStyle: 'bold',
        },
        gridLines: {
          drawOnChartArea: false,
          color: 'rgba(102, 106, 150, 0.3)',
        },
      }],
    },
  }

  get weekly() {
    return Number(this.$route.params.daysAgo) === 7;
  }

  get daysAgo() {
	  if (Number(this.$route.params.daysAgo)) {
		  return Number(this.$route.params.daysAgo)
	  } else {
		  return -1
	  }
  }

  get reportJobCount() {
    return reportModule.jobCount;
  }

  get topDeferment(): any {
    return operationsModule.topDefermentArray;
  }

  get actionTallies(): any {
    return reportModule.actionTallies;
  }

  get cycleTimeData(): any {
    return analysisModule.cycleTimeChart;
  }

  get defermentByReason(): any {
    return reportModule.defermentByReason;
  }

  get jobCompletion(): any {
    return reportModule.jobCompletionPercentage;
  }

  getUnits() {
	  if (getConfigEnv('OPERATOR_LOWERCASED') == "swn") {
		  return "mcf"
	  } else {
		  return "bpd"
	  }
  }


  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async created() {
    this.startDataLoading();
await workflowModule.getOppPercentage()
 this.startDataLoading();
await analysisModule.getCycleTimeChart()
 this.startDataLoading();
	// @ts-ignore
await operationsModule.getDefermentAggLatest({forReport: true, daysAgo: this.daysAgo})
 this.initializeChardLoad()

  
   
    // promises.push(reportModule.getJobCount({ daysAgo: this.daysAgo }));
    // promises.push(reportModule.getActionTallies({ daysAgo: this.daysAgo }));
	// promises.push(analysisModule.getCycleTimeChart());
	// promises.push(reportModule.getJobCompletionPercentage({ daysAgo: this.daysAgo }));
	// promises.push(reportModule.getDefermentByReason({ daysAgo: this.daysAgo }));




 

    // await reportModule.getJobCount({ weekly: this.weekly });
    // await reportModule.getActionTallies({ weekly: this.weekly });
    // await analysisModule.getCycleTimeChart();
    // await reportModule.getJobCompletionPercentage({ weekly: this.weekly });
	// await reportModule.getDefermentByReason({ weekly: this.weekly });
	
	
  }

  async initializeChardLoad() {

	  this.startDataLoading();

		
		// await this.$store.dispatch(SET_JOB_COUNTS, { weekly: this.weekly });
		this.rankings = this.reportJobCount.slice(0, 10);

		this.topWells = this.topDeferment.slice(0, 10);

// {"No action needed":3,"On site fix":1,"On site replacement":0,"Change setpoint":0,"Troubleshooting":4}



		this.actionsChartData.datasets[0].backgroundColor = this.colors.slice(0, this.actionsChartData.labels.length - 2)
		this.actionsChartData.datasets[0].hoverBackgroundColor = this.colors.slice(0, this.actionsChartData.labels.length - 2)
		this.actionsChartData.datasets[0].borderColor = this.colors.slice(0, this.actionsChartData.labels.length - 2)




      // let backgroundColorBar = ['#f0ebad', '#FF00FF', '#0000CD', '#48D1CC', '#48D1CC'];
       let backgroundColorBar = ['#61E1A0', '#5155BA', '#FED236', '#257EF0', '#9F3F80','#f0ebad', '#FF00FF', '#0000CD', '#48D1CC', '#48D1CC'];
    //   hoverBackgroundColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#d43da6'],
    //   borderColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#d43da6'],

		this.actionsChartData.labels = this.actionTallies.map((i) => i[0]);
		this.actionsChartData.datasets[0].data = this.actionTallies.map((i) => i[1]);
		this.actionsTotal = this.actionsChartData.datasets[0].data.reduce((a, b) => a + b, 0);


		this.cycleChartData.datasets[0].data = this.cycleTimeData;

		// console.log( this.defermentByReason.datasets)
		// // this.barChartData.datasets = this.defermentByReason.datasets;
    // console.log(Object.keys(workflowModule.defermentStats).filter(k => { return k !== 'labels'} ).map(s => {return {

    //   label: s,
    //       backgroundColor: '#f87979',
    //       data: workflowModule.defermentStats[s]
    // }}))


    let dataSetForCategory = Object.keys(workflowModule.defermentCategoryStats).filter(k => { return k !== 'labels'} ).map((s,i) => {return {

      label: s.toUpperCase() == 'HILINE RTD' ? 'LINE PRESSURE' : s.toUpperCase(),
          backgroundColor: backgroundColorBar[i],
          data: workflowModule.defermentCategoryStats[s],
          sum: workflowModule.defermentCategoryStats[s].reduce((partial_sum, a) => partial_sum + a, 0)
    }}).sort((a, b) => parseFloat(b.sum) - parseFloat(a.sum)).map((g,k) => {
      g['backgroundColor'] =  backgroundColorBar[k]
      return g
    });

    let RTD: any = []
    let Uncategory: any = []
    dataSetForCategory.forEach((e, idx) => {
      if(e.label && e.label.toLowerCase().includes('rtd')){
        RTD.push(e)
      }else if(e.label && e.label.toLowerCase().includes('uncate')){
        Uncategory.push(e)
      }
    })

    console.log(Uncategory)
    
    if(RTD && RTD.length){
      let rtdData = RTD.map((k => {
       return k.data
      }))
     RTD[0].sum = RTD.reduce((a, b) => a.sum + b.sum, 0)
     RTD[0].label = "RTD"
     RTD[0].data =  RTD[0].data.map((x, idx) => rtdData.reduce((sum, curr) => sum + curr[idx], 0));
    }

    

   dataSetForCategory = dataSetForCategory.filter((e) => !e.label.toLowerCase().includes('rtd') && !e.label.toLowerCase().includes('uncate'))
    let sumUpData: any =  dataSetForCategory.sort((a,b) => b.sum - a.sum).filter((a , idx)=> idx >= 3)
     dataSetForCategory = dataSetForCategory.sort((a,b) => b.sum - a.sum).filter((a , idx)=> idx < 3)
    if(sumUpData && sumUpData.length){
      let totalSum = sumUpData.reduce((a, b) => a.sum + b.sum, 0) 
      totalSum += Uncategory[0].sum

      if(Uncategory && Uncategory.length){
        Uncategory[0].sum = totalSum;
          let uncategoryData = sumUpData.map((k => {
            // console.log(k.data)
       return k.data
      }))
        Uncategory[0].backgroundColor = '#f0ebad'

        Uncategory[0].data =  RTD[0].data.map((x, idx) => uncategoryData.reduce((sum, curr) => sum + curr[idx], 0));

        dataSetForCategory.push(Uncategory[0])
      }
    }
   dataSetForCategory.push(RTD[0])
    dataSetForCategory = dataSetForCategory.sort((a,b) => b.sum - a.sum)

    let percentageActioned = workflowModule.defermentStats['actioned'].map((a,i) => {
      return (a/workflowModule.defermentStats['total'][i])*100
    })
    this.actionChartData.labels = workflowModule.defermentStats.labels
    this.actionChartData.datasets[0].data = percentageActioned

	this.barChartData.datasets = dataSetForCategory
	this.barChartData.labels = workflowModule.defermentStats.labels;

	this.completionChartData.labels = this.jobCompletion.labels;
	this.completionChartData.datasets[0].data = this.jobCompletion.data;

	await this.sleep(100)
	this.sleepBeforeChartLoad(500)
		

  }

  async sleepBeforeChartLoad(time) {
	this.startDataLoading();
	await this.sleep(time)
	this.stopDataLoading();

  }

}




// import { mapGetters } from 'vuex';
// import {
//   SET_ACTION_TALLIES_DATA,
//   SET_CYCLE_TIME_METRICS,
//   SET_TL_JOB_COUNT,
//   SET_OPERATIONS_TOP_DEFERMENT_DATA,
//   SET_DEFERMENT_BY_REASON,
//   SET_REASSIGNMENT_LIST,
//   SET_JOB_COMPLETION_PERCENTAGE,
//   SET_JOB_COUNTS,
// } from 'Store/mutation-types';
//
// const Report = {
//   computed: {
//     ...mapGetters({
//       defermentData: 'operationsTopDeferment',
//       actionTallies: 'actionTallies',
//       tlJobCount: 'tlJobCount',
//       defermentByReason: 'defermentByReason',
//       cycleTimeData: 'cycleTimeData',
//       jobCounts: 'jobCounts',
//       jobCompletion: 'jobCompletion',
//     }),
//   },
// };
// export default Report;
